<template>
  <div class="row mb-5">
    <div class="col-12">
      <div class="chargers mb-4">
        <div class="row subheader text-center">
          <div class="col-auto mx-auto"><h4>Suggested Equipment</h4></div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-2 mb-3 mb-lg-0 text-center">
            <img class="img-fluid" :src="chargerImg" :alt="charger.name + ' home electric vehicle charging station'" />
          </div>
          <div class="col-12 col-md-8 col-lg-6 description">
            <h2>
              {{ charger.name }}
              <span class="accent">{{ toCurrency(charger.price, 0) }}</span>
            </h2>
            <p>{{ charger.description }}</p>
            <h4>
              Installation Cost (estimated):
              <span class="accent"
                >{{ toCurrency(charger.installationPriceLow, 0) }}-{{
                  toNumber(charger.installationPriceHigh, 0)
                }}</span
              >
            </h4>
            <p class="small">
              Installation costs are highly variable and depend on several
              factors, including whether your electrical panel needs an upgrade.
              Also, the distance between the charger and your main electrical
              panel can impact the cost.
            </p>
            <a id="AmazonChargerLink" class="btn button big d-none d-md-inline" :href="charger.url" target="_blank"
              >buy from amazon <span class="accent">&raquo;</span></a
            >
          </div>
          <div class="col-12 col-md-4 col-lg-3 ml-lg-5 features">
            <h4>Features</h4>
            <ul>
              <li v-for="(feature, idx) in charger.features" :key="idx">
                {{ feature }}
              </li>
            </ul>
          </div>
          <div class="col-12 text-center mt-3 d-md-none"> <a id="AmazonChargerLink" class="btn button big" :href="charger.url" target="_blank"
              >buy from amazon <span class="accent">&raquo;</span></a
            ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Constants } from "Services/Constants";
import common from "Mixins/common";
import { IMAGE_URL } from "../config";

export default {
  props: {
    vehicle: Object,
  },
  data() {
    //Hard coded to 3 for now. This is the Enel X Juice Box 40
    let id = 3;
    //if (this.vehicle.Make !== "Tesla") id = 2;    
    let charger = Constants.chargers.find((x) => x.id === id);

    return {
      charger
    };
  },
  computed: {
    chargerImg() {
      if (!this.charger.imgName) {
        return;
      }

      return `${IMAGE_URL}/chargers/${this.charger.imgName}.jpg`
    },
  },
  mixins: [common],
};
</script>
<style lang="scss" scoped>
.chargers {
  img {
    border: 2px solid $img-border-color;
  }

  .subheader {
    padding-bottom: 10px;
  }
  .description {
    p {
      margin-bottom: 30px;
    }
  }

  .features {
    padding-top: 7px;
    padding-left: 3px;
    border: 2px solid $img-border-color;

    h4 {
      padding-left: 39px;
    }

    li {
      color: $secondary-font-color-dark;
      font-size: 0.75rem;
      padding-bottom: 2px;
      list-style-image: url("~Images/leaf_bullet.png");
    }
  }
}

h2 .accent,
h4 .accent {
  color: $secondary-font-color-dark;

  .small {
    font-size: 0.75rem;
  }
}
h2 {
  font-weight: 650;
  font-size: 1.4rem;
}
h4 {
  font-size: 1rem;
}
p {
  font-size: 0.8rem;
  color: $secondary-font-color-dark;

  &.small {
    font-size: 0.775rem;
  }
}

.solar-panels {
  p {
    padding-right: 40%;
  }
}

.btn.button {
  width: 300px;
  color: $primary-button-font-color !important;
}
</style>
